import { Injectable } from '@angular/core';
import { TranslocoLoader } from '@jsverse/transloco';

@Injectable()
export class I18nLoader implements TranslocoLoader {
  // https://netbasal.com/strategies-for-cache-busting-translation-files-in-angular-86143ee14c3c
  public getTranslation(lang: string) {
    return import(`../../../assets/i18n/${lang}.json`).then((res) => res.default);
  }
}
