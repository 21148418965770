import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { timeout } from 'rxjs';
import { hasCustomTimeout } from './http-timeout.context';

export const httpTimeoutInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const customTimeout = hasCustomTimeout(req);
  if (typeof customTimeout === 'number' && customTimeout > 0) {
    return next(req).pipe(timeout(customTimeout));
  }

  return next(req);
};
