import { createEnvironment } from './environment.model';
import { ORIGIN_UAT_APP } from './_origin';

export const environment = createEnvironment({
  production: true,
  name: 'uat',
  encryptionStorage: 'aes',
  sentry: {
    sampleRate: 1.0,
  },
  origin: {
    app: ORIGIN_UAT_APP,
  },
});
