import { Component, ViewEncapsulation, ChangeDetectionStrategy, NgZone, inject, input, computed } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupButton, PopupButtonEvent, PopupData } from './popup-button.model';
import { ButtonComponent } from '@isaia/components/button';
import { isFunction } from 'lodash-es';

@Component({
  standalone: true,
  selector: 'isa-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent],
})
export class PopupComponent {
  public readonly dialogRef: MatDialogRef<PopupComponent, PopupButtonEvent> = inject(MatDialogRef);
  public readonly data: PopupData<PopupButton> = inject(MAT_DIALOG_DATA);
  private readonly ngZone = inject(NgZone);

  public title = input(this.data.title);
  public message = input(this.data.message);
  public buttons = input(this.data.buttons);
  public hasButtons = computed(() => !!this.buttons()?.length);

  constructor() {
    this.shouldPatchZone();
  }

  public onButtonClick(e: Event, index: number) {
    return this.dialogRef.close(this.createPopupEvent(e, index, this.buttons()));
  }

  private shouldPatchZone() {
    if (this.ngZone.isStable) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.ngZone.run(() => {});
    }
  }

  private createPopupEvent<T>(e: Event, index: number, buttons?: PopupButton<T>[]): PopupButtonEvent<T> {
    const baseEvent = { event: e, index };
    const action = buttons?.[index]?.onTap;
    return { ...baseEvent, action: isFunction(action) ? action(baseEvent) : action };
  }
}
