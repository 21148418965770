import { effect, Injector, makeEnvironmentProviders } from '@angular/core';
import SecureLS from 'secure-ls';

type LocalStorageConfiguration = { encryptionStorage?: 'aes' | '' | string };

export function provideLocalStorage(config: LocalStorageConfiguration) {
  return makeEnvironmentProviders([
    {
      provide: LocalStorageService,
      useFactory: () => new LocalStorageService(config),
    },
  ]);
}

export class LocalStorageService {
  public ENCRYPTION = '_isa__encryption';
  private PERSISTENT_KEYS?: string[];
  private encryption?: SecureLS;

  constructor(private readonly config?: LocalStorageConfiguration) {
    this.shouldInitEncryption();
  }

  public get<T = unknown>(key: string): T {
    return this.encryption?.get(key);
  }

  public set(key: string, value: unknown) {
    this.encryption?.set(key, value);
  }

  public remove(key: string) {
    window.localStorage.removeItem(key);
  }

  public clear(options?: { safe?: boolean }) {
    if (options?.safe !== false) {
      const persistents = this.PERSISTENT_KEYS?.map((key) => {
        return { key, value: window.localStorage.getItem(key) };
      });
      window.localStorage.clear();
      persistents?.forEach((persistent) => {
        window.localStorage.setItem(persistent.key, persistent.value || '');
      });
    } else {
      window.localStorage.clear();
    }
  }

  public isEncrypted() {
    return JSON.parse(localStorage.getItem(this.ENCRYPTION) || '');
  }

  public syncValue<V>(key: string, getter: () => V, setter: (value: V | undefined) => void, injector?: Injector) {
    try {
      const value = this.get<V>(key);
      setter(value);
    } catch (e: unknown) {
      setter(undefined);
    }
    effect(() => this.set(key, getter()), { injector });
  }

  public initEncryption(encodingType?: string) {
    this.encryption = new SecureLS({
      encodingType,
      isCompression: false,
    });
    this.PERSISTENT_KEYS = [this.ENCRYPTION, (this.encryption as any)?.utils?.metaKey].filter(Boolean);
  }

  public shouldInitEncryption() {
    const encryptionStorage = this.config?.encryptionStorage;
    const hasEncryption = !!encryptionStorage;
    const clearConfig = { safe: false };

    try {
      const value = localStorage.getItem(this.ENCRYPTION);
      const wasEncrypted = JSON.parse(value || '');
      if (wasEncrypted !== hasEncryption) {
        this.clear(clearConfig);
      }
      this.initEncryption(encryptionStorage);
    } catch (err) {
      this.clear(clearConfig);
      this.initEncryption(encryptionStorage);
    }

    localStorage.setItem(this.ENCRYPTION, hasEncryption.toString());
  }
}
