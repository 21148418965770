import { computed, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { LocalStorageService } from '@isaia/local-storage';
import { AuthUser } from './auth-user.model';
import { clone } from '@isaia/clone';
import { defineSentryUser } from '../sentry/sentry.provider';
import { getUserFullName } from './auth-full-name.pipe';

export interface AuthState {
  token?: string;
  user?: AuthUser;
  config?: {
    storeId?: string | null;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  private readonly localStorageService = inject(LocalStorageService);
  private readonly $store = signal<AuthState | undefined>(undefined);

  private readonly $config = computed(() => this.$store()?.config);
  public readonly $user = computed(() => this.$store()?.user);
  public readonly $token = computed(() => this.$store()?.token);
  public readonly $email = computed(() => this.$user()?.email || '');
  public readonly sentToProductionEmails = computed(() => this.$user()?.sentToProductionEmails || []);
  public readonly $isLoggedIn = computed(() => !!this.$token() && !!this.$user()?.email);
  public readonly $storeId = computed(() => this.$config()?.storeId || this.$user()?.defaultStore);
  public readonly fullName = computed(() => getUserFullName(this.$user()));

  constructor() {
    this.localStorageService.syncValue(
      'auth',
      () => this.$store(),
      (state) => this.setStore(state),
    );
  }

  public setStore(state?: AuthState) {
    defineSentryUser(state?.user);
    this.$store.set(state);
  }

  public setToken(token: AuthState['token']) {
    this.$store.update((state) => {
      return { token, user: undefined };
    });
  }

  public updateToken(token: AuthState['token']) {
    this.$store.update((state) => {
      return { ...state, token };
    });
  }

  public setUser(user: AuthState['user']) {
    defineSentryUser(user);
    this.$store.update((state) => {
      const store = clone(state)!;
      store.user = user;
      store.config = {};
      return store;
    });
  }

  public updateConfig(config: AuthState['config']) {
    this.$store.update((state) => {
      return { ...state, config: { ...state?.config, ...config } };
    });
  }

  public onLogout(fn: () => void) {
    return effect(() => {
      if (!this.$isLoggedIn()) {
        untracked(fn);
      }
    });
  }
}
