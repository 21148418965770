import { EmptyUrlResolverParams, RouterResolver } from '@isaia/router';

export const resolveAuthRoutes = (resolver: RouterResolver) => {
  return resolver.createFeatureResolver('auth', (rootPath) => {
    return {
      login: (options?: EmptyUrlResolverParams) => {
        const url = resolver.createUrlTree([rootPath, 'login'], options);
        return resolver.createUrlNavigableData(url);
      },
    };
  });
};
