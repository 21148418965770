import { Pipe, PipeTransform } from '@angular/core';

type UserFullNameOptions = { firstName?: string; lastName?: string; username?: string };

export function getUserFullName<T extends UserFullNameOptions>(user?: T) {
  return `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || user?.username || '';
}

@Pipe({
  standalone: true,
  name: 'isaAuthFullName',
})
export class AuthFullNamePipe implements PipeTransform {
  public transform<T extends UserFullNameOptions>(user?: T) {
    return getUserFullName(user);
  }
}
