import { APP_INITIALIZER, inject } from '@angular/core';
import { ConfigService } from './config.service';
import { firstValueFrom } from 'rxjs';

export function provideConfigInitializer() {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => {
      const configService = inject(ConfigService);
      return () => {
        return firstValueFrom(configService.getConfig());
      };
    },
  };
}
