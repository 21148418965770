function reloadApplication() {
  setTimeout(() => (window.location as any).reload(true), 0);
}

export function showApplicationUpdateMessage(isForceUpdate: boolean): void {
  if (isForceUpdate) {
    alert('New version available. MTM will be reloaded with latest changes.');
    return reloadApplication();
  }

  const update = confirm('New version available. MTM will be reloaded with latest changes, do you want to proceed?');
  if (update) {
    reloadApplication();
  }
}
