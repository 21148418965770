import { EmptyUrlResolverParams, RouterResolver, UrlResolverOptions } from '@isaia/router';

export const resolveCustomerRoutes = (resolver: RouterResolver) => {
  return resolver.createFeatureResolver('customer', (rootPath) => {
    return {
      list: (options?: EmptyUrlResolverParams) => {
        const url = resolver.createUrlTree([rootPath, 'list'], options);
        return resolver.createUrlNavigableData(url);
      },
      detail: (options: UrlResolverOptions<{ customerId: string }>) => {
        const url = resolver.createUrlTree([rootPath, options?.params.customerId, 'detail'], options);
        return resolver.createUrlNavigableData(url);
      },

      item: {
        create: (options: UrlResolverOptions<{ customerId: string }, { categoryId: string | number }>) => {
          const { customerId } = options.params;
          const url = resolver.createUrlTree([rootPath, customerId, 'item', 'create'], options);
          return resolver.createUrlNavigableData(url);
        },
      },

      measurement: {
        list: (options: UrlResolverOptions<{ customerId: string }, { categoryId: string | number }>) => {
          const { customerId } = options.params;
          const url = resolver.createUrlTree([rootPath, customerId, 'measurement', 'list'], options);
          return resolver.createUrlNavigableData(url);
        },
      },
    };
  });
};
