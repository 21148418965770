import { inject, InjectionToken, makeEnvironmentProviders } from '@angular/core';

type ImageLoaderConfig = { fallbackImagePath: string };
const IMAGE_LOADER_CONFIG = new InjectionToken<ImageLoaderConfig>('Image loader config provider');

export function provideImageLoaderConfig(config: ImageLoaderConfig) {
  return makeEnvironmentProviders([
    {
      provide: IMAGE_LOADER_CONFIG,
      useValue: config,
    },
  ]);
}

export function injectImageLoaderConfig() {
  return inject(IMAGE_LOADER_CONFIG, { optional: true });
}
