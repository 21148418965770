import { ErrorHandler, Provider } from '@angular/core';
import { createSentryError } from '../sentry';
import { showApplicationUpdateMessage } from '../application/application.notify-update';

export class CustomErrorHandler extends ErrorHandler {
  private readonly chunkFailedMessage = /Loading chunk [\d]+ failed/;
  private readonly sentry = createSentryError();

  public override handleError(error: any) {
    if (error !== undefined) {
      this.detectChunkError(error);
      this.sentry.handleError(error);
    }
  }

  private detectChunkError(error?: any) {
    if (this.chunkFailedMessage.test(error?.message)) {
      showApplicationUpdateMessage(true);
    }
  }
}

export function provideCustomErrorHandler(): Provider {
  return { provide: ErrorHandler, useClass: CustomErrorHandler };
}
