import { EmptyUrlResolverParams, RouterResolver } from '@isaia/router';

export const resolveBasketRoutes = (resolver: RouterResolver) => {
  return resolver.createFeatureResolver('basket', (rootPath) => {
    const product = resolver.createFeatureResolver(rootPath + '/product', (basketProductRoot) => {
      return {
        list: (options?: EmptyUrlResolverParams) => {
          const url = resolver.createUrlTree([basketProductRoot, 'list'], options);
          return resolver.createUrlNavigableData(url);
        },
      };
    });

    return {
      product,
    };
  });
};
