import { InjectionToken, ValueProvider } from '@angular/core';

export type ConfigApiOrigin = {
  origin: string;
  polling: { timingInSeconds: number; errorWaitForRetryInSeconds: number };
};
export const CONFIG_API_ORIGIN = new InjectionToken<ConfigApiOrigin>('Config API Origin');
export const provideConfigApiOrigin = (origin: ConfigApiOrigin): ValueProvider => ({
  provide: CONFIG_API_ORIGIN,
  useValue: origin,
});
