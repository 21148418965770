import { HttpErrorCode } from '@isaia/entity/http';
import { translate } from '@jsverse/transloco';

type SessionLockedExceptionOptions = { fullName: string };
type SessionLockedStoreExceptionOptions = { storeName: string };

type ErrorTranslator = typeof translate;

export const ERROR_MESSAGE = {
  [HttpErrorCode.GenericErrorCode]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.GenericErrorCode}`);
  },
  [HttpErrorCode.SmtpConnectionError]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.SmtpConnectionError}`);
  },
  [HttpErrorCode.SendMailError]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.SendMailError}`);
  },
  [HttpErrorCode.AuthGenericAwsError]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.AuthGenericAwsError}`);
  },
  [HttpErrorCode.AuthNotValidToken]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.AuthNotValidToken}`);
  },
  [HttpErrorCode.FtpConnectionError]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.FtpConnectionError}`);
  },
  [HttpErrorCode.OrderItemSendToProductionError]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.OrderItemSendToProductionError}`);
  },
  [HttpErrorCode.OrderItemGenericError]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.OrderItemGenericError}`);
  },
  [HttpErrorCode.CustomerExistError]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.CustomerExistError}`);
  },
  [HttpErrorCode.SessionNotFound]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.SessionNotFound}`);
  },
  [HttpErrorCode.CustomerNotFoundError]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.CustomerNotFoundError}`);
  },
  [HttpErrorCode.SessionLockedException]: (translate: ErrorTranslator, options?: SessionLockedExceptionOptions) => {
    return translate(`error.${HttpErrorCode.SessionLockedException}`, options);
  },
  [HttpErrorCode.SessionLockedStoreException]: (translate: ErrorTranslator, options?: SessionLockedStoreExceptionOptions) => {
    return translate(`error.${HttpErrorCode.SessionLockedStoreException}`, options);
  },
  [HttpErrorCode.OrderItemReferenceIdAlreadyExist]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.OrderItemReferenceIdAlreadyExist}`);
  },
  [HttpErrorCode.ErrorOnUploadMaxFileSize]: (translate: ErrorTranslator) => {
    return translate(`error.${HttpErrorCode.ErrorOnUploadMaxFileSize}`);
  },
} as const;
