import { inject } from '@angular/core';
import { ConfigService } from './config.service';
import { filter } from 'rxjs';
import { ConfigStatusType } from './config.model';
import { RouterResolverService } from '../router';
import { AuthGuard } from '../auth';

export function hasConfigReady() {
  return inject(ConfigService).isReady$.pipe(filter((v) => v === true));
}

export function canActivateMaintenance() {
  const isInMaintenance = inject(ConfigService).statusType() === ConfigStatusType.Maintenance;
  return isInMaintenance ? true : inject(AuthGuard).redirectToLoggedRoute({ returnUrlTree: true });
}

export function canActivateApplicationByConfigStatus() {
  const routerResolverService = inject(RouterResolverService);
  const isNotInMaintenance = inject(ConfigService).statusType() !== ConfigStatusType.Maintenance;
  return isNotInMaintenance ? true : routerResolverService.parseUrl(routerResolverService.maintenance.url);
}
