import { effect, inject, Injectable } from '@angular/core';
import { AuthRepository } from './auth.repository';
import { firstValueFrom, map, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpResponse } from '@isaia/entity/http';
import { withAuthentication } from './auth.context';
import { AuthGuard } from './auth.guard';
import { AuthUser } from './auth-user.model';
import { DialogService } from '@isaia/components/dialog';
import { RouterResolverService } from '../router/router.resolver';

type GetCognitoAttribute = {
  (prop: CognitoAuthProp, param: { toArray?: boolean }): string[];
  (prop: CognitoAuthProp): string;
  (prop: CognitoAuthProp, param?: { toArray?: boolean }): string | string[];
};

enum CognitoAuthProp {
  Email = 'email',
  FirstName = 'given_name',
  LastName = 'family_name',
  DefaultStore = 'custom:defaultStore',
  AllowedStores = 'custom:allowedStores',
  SendToProductionEmails = 'custom:productionEmails',
}

type HttpGetAuthUserResponse = HttpResponse<{
  Username: string;
  UserAttributes: [
    { Name: 'sub'; Value: string },
    { Name: CognitoAuthProp.Email; Value: string },
    { Name: CognitoAuthProp.FirstName; Value: string },
    { Name: CognitoAuthProp.LastName; Value: string },
    { Name: 'email_verified'; Value: 'true' | 'false' },
    { Name: CognitoAuthProp.DefaultStore; Value: string },
    { Name: CognitoAuthProp.AllowedStores; Value: string },
    { Name: CognitoAuthProp.SendToProductionEmails; Value: string },
    { Name: 'custom:store'; Value: string },
  ];
}>;

const REQUIRED_USER_PROPS: Array<keyof AuthUser> = ['email', 'username', 'defaultStore'];

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly api = environment.api.auth;
  private readonly httpClient = inject(HttpClient);
  private readonly authRepository = inject(AuthRepository);
  private readonly authGuard = inject(AuthGuard);
  private readonly dialogService = inject(DialogService);
  private readonly routerResolverService = inject(RouterResolverService);

  constructor() {
    effect(() => {
      this.redirectOnLogout();
    });
  }

  private redirectOnLogout() {
    if (!this.authRepository.$isLoggedIn()) {
      this.authGuard.redirectToNotLoggedRoute();
    }
  }

  public redirectToLoginProvider() {
    const redirect$ = this.httpClient.get<HttpResponse<string>>(this.api.redirectLogin, {
      params: { redirect_uri: this.api.redirectPostLogin },
    });
    return firstValueFrom(redirect$).then((res) => {
      const url = res.data;
      if (!url) {
        throw new Error('[authService.redirectToLoginProvider]: url not found');
      }
      window.location.href = url;
    });
  }

  public login(options: { code: string }) {
    const login$ = this.decodeCodeProvider(options.code).pipe(
      switchMap((val) => {
        const token = val.data.token;
        this.authRepository.setToken(token);
        return this.getUser().pipe(
          tap((user) => {
            this.authRepository.setUser(user.data);
          }),
        );
      }),
    );
    return firstValueFrom(login$).catch((e) => {
      this.logout();
      throw e;
    });
  }

  private decodeCodeProvider(code: string) {
    return this.httpClient.get<HttpResponse<{ token: string }>>(this.api.decode, {
      context: withAuthentication(false),
      params: { redirect_uri: this.api.redirectPostLogin, code },
    });
  }

  private getUser() {
    return this.httpClient.get<HttpGetAuthUserResponse>(this.api.user).pipe(
      map((res) => {
        const { UserAttributes, Username } = res.data;
        const getAttribute = this.getCognitoAttribute(UserAttributes);
        const user: AuthUser = {
          username: Username,
          firstName: getAttribute(CognitoAuthProp.FirstName),
          lastName: getAttribute(CognitoAuthProp.LastName),
          email: getAttribute(CognitoAuthProp.Email),
          defaultStore: getAttribute(CognitoAuthProp.DefaultStore),
          sentToProductionEmails: getAttribute(CognitoAuthProp.SendToProductionEmails, { toArray: true }),
          allowedStores: getAttribute(CognitoAuthProp.AllowedStores, { toArray: true }),
        };
        this.throwErrorOnInvalidUser(user);
        return { ...res, data: user };
      }),
    );
  }

  private throwErrorOnInvalidUser(user?: AuthUser) {
    const invalidKeys = REQUIRED_USER_PROPS.filter((prop) => !user?.[prop]);
    if (invalidKeys.length) {
      const message = `Invalid user, missed keys: ${invalidKeys.join(', ')}.\nPlease contact the assistance.`;
      this.dialogService.openPopup({ title: 'Auth Error', message }, { disableClose: true });
      this.routerResolverService.updateUrlWithoutReload({ queryParams: { code: null } });
      throw new Error(message);
    }
  }

  private getCognitoAttribute(data: HttpGetAuthUserResponse['data']['UserAttributes']): GetCognitoAttribute {
    return (prop: CognitoAuthProp, options?: { toArray?: boolean }): any => {
      const value = (data.find((att) => att.Name === prop)?.Value as string) || '';
      if (options?.toArray) {
        return (
          value
            .split(',')
            .filter((v) => !!v)
            .map((v) => v.trim()) || []
        );
      }
      return value.trim();
    };
  }

  public logout() {
    this.authRepository.setStore(undefined);
    this.authGuard.redirectToNotLoggedRoute();
    const logout$ = this.httpClient.get<HttpResponse<{ status: 'success' }>>(this.api.logout);
    return firstValueFrom(logout$);
  }
}
