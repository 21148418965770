import { EmptyUrlResolverParams, RouterResolver } from '@isaia/router';

export const resolveSessionRoutes = (resolver: RouterResolver) => {
  return resolver.createFeatureResolver('session', (rootPath) => {
    return {
      list: (options?: EmptyUrlResolverParams) => {
        const url = resolver.createUrlTree([rootPath, 'list'], options);
        return resolver.createUrlNavigableData(url);
      },
    };
  });
};
