import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, NgZone, TemplateRef } from '@angular/core';
import { PopupComponent, PopupData, PopupButtonEvent, ExtractPopupResponse } from './popup';
import { Modal } from './modal';

let idCounter = -1;

export interface DialogConfig<D = unknown> extends MatDialogConfig<D> {
  type?: 'expanded' | 'fixed';
}

const BASE_DIALOG_CONFIG: DialogConfig = {
  autoFocus: false,
  closeOnNavigation: false,
  minWidth: 300,
  maxWidth: 'calc(100% - 16px * 2)',
  restoreFocus: false,
  disableClose: true,
};

@Injectable()
export class DialogService {
  private readonly dialog = inject(MatDialog);
  private readonly ngZone = inject(NgZone);

  private patchNgZoneDialog<T>(fn: () => T): T {
    return NgZone.isInAngularZone() ? fn() : this.ngZone.run(fn);
  }

  public openModal<T extends Modal>(component: ComponentType<T> | TemplateRef<T>, config = {} as DialogConfig<T['data']>) {
    return this.patchNgZoneDialog(() => {
      return this.dialog.open<T, DialogConfig<T['data']>, T['ɵɵreturnValueType']>(component, {
        id: `isa-modal--${++idCounter}`,
        ...BASE_DIALOG_CONFIG,
        ...config,
        data: { ...config.data },
        panelClass: this.mergeDomClass(['isa-dialog', 'isa-dialog--modal', this.getTypeClassName(config.type)], config.panelClass),
        backdropClass: this.mergeDomClass(['cdk-overlay-dark-backdrop', 'isa-dialog--modal-backdrop'], config.backdropClass),
      });
    });
  }

  public openPopup<TData extends PopupData, TConfig extends Omit<DialogConfig, 'data'>>(data: TData, config = {} as TConfig) {
    return this.patchNgZoneDialog(() => {
      return this.dialog.open<
        PopupComponent,
        DialogConfig<TData>,
        TData extends { buttons?: infer TButtons } ? PopupButtonEvent<ExtractPopupResponse<TButtons>> : undefined
      >(PopupComponent, {
        id: `isa-popup--${++idCounter}`,
        ...BASE_DIALOG_CONFIG,
        ...config,
        data: { ...data },
        panelClass: this.mergeDomClass(['isa-dialog', 'isa-dialog--popup', this.getTypeClassName(config.type)], config.panelClass),
        backdropClass: this.mergeDomClass(['cdk-overlay-dark-backdrop', 'isa-dialog--popup-backdrop'], config.backdropClass),
      });
    });
  }

  private getTypeClassName(type: DialogConfig['type']) {
    return type ? `isa-dialog--${type}` : '';
  }

  private mergeDomClass<D = any>(base: string[], classes: MatDialogConfig<D>['panelClass']): string[] {
    if (!classes) {
      return base;
    }
    const values = Array.isArray(classes) ? [...base, ...classes] : [...base, classes];
    return values.filter((value) => !!value);
  }

  public closeAll() {
    return this.dialog.closeAll();
  }

  public get openDialogs() {
    return this.dialog.openDialogs;
  }

  public get afterAllClosed() {
    return this.dialog.afterAllClosed;
  }
}
