import { isPlainObject } from 'lodash-es';
import type { ButtonColor, ButtonType } from '@isaia/components/button';

type PopupOnTapConfig = Omit<PopupButtonEvent, 'action'>;
type OnTapFn<TValue = undefined> = (config: PopupOnTapConfig) => TValue;

export interface PopupButton<T = undefined> {
  label: string;
  className?: string;
  color?: ButtonColor;
  type?: ButtonType;
  disabled?: boolean;
  onTap?: T | OnTapFn;
}

export interface PopupButtonEvent<T = any> {
  event: Event;
  index: number;
  action?: T;
}

export interface PopupData<TButton extends PopupButton = any> {
  title?: string;
  message?: string;
  buttons?: TButton[];
}

export type ExtractPopupResponse<TButtons> = TButtons extends Array<infer TButton>
  ? TButton extends { onTap?: infer TValue }
    ? TValue extends (...args: any[]) => infer TReturnValue
      ? TReturnValue
      : TValue
    : void
  : void;

export function createPopupButton<T = undefined>(config: PopupButton<T>): PopupButton<T> {
  return {
    label: config.label,
    className: config.className,
    color: config.color,
    type: config.type,
    disabled: config.disabled,
    onTap: 'onTap' in config ? ((isPlainObject(config.onTap) ? { ...config.onTap } : config.onTap) as T) : undefined,
  };
}
