import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { provideLocalStorage } from '@isaia/local-storage';
import { provideDialog } from '@isaia/components/dialog';
import { provideLanguage } from '@isaia/i18n/language';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './core/auth';
import { environment } from '../environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideImageLoaderConfig } from '@isaia/components/image-loader';
import { httpTimeoutInterceptor, httpErrorInterceptor, removeEmptyQueryParamsInterceptor } from './core/http';
import { provideSentry } from './core/sentry';
import { provideRouterReuseStrategy } from './core/router';
import { provideCustomErrorHandler } from './core/error';
import { provideConfig } from './core/config';
import { I18nLoader } from './core/i18n';
import { providePriceHidable } from '@isaia/components/price-hidable';

export const appConfig: ApplicationConfig = {
  providers: [
    provideConfig({ origin: environment.api.config, polling: environment.config.polling }),
    provideAnimations(),
    provideHttpClient(withInterceptors([httpTimeoutInterceptor, httpErrorInterceptor, removeEmptyQueryParamsInterceptor, authInterceptor])),
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'disabled' }),
      withPreloading(PreloadAllModules),
    ),
    provideRouterReuseStrategy(),
    provideLocalStorage({ encryptionStorage: environment.encryptionStorage }),
    provideDialog(),
    providePriceHidable(),
    provideLanguage({
      origin: environment.api.i18n,
      loader: I18nLoader,
      config: {
        availableLangs: ['en', 'it'],
        prodMode: !isDevMode(),
      },
    }),
    provideImageLoaderConfig({ fallbackImagePath: '/assets/image-not-found-gesture.png' }),
    environment.production
      ? provideSentry({
          dsn: environment.sentry.dsn,
          environment: environment.name,
          version: environment.version,
          sampleRate: environment.sentry.sampleRate, // Capture 100% of the transactions, reduce in production!
        })
      : [],
    provideZoneChangeDetection({ eventCoalescing: true, runCoalescing: true }),
    provideCustomErrorHandler(),
  ],
};
