import { HttpContextToken, HttpRequest } from '@angular/common/http';
import { WithContextOptions, extractHttpContextAndValue } from '../http/http-context.model';

const WITH_AUTHENTICATION = new HttpContextToken<boolean>(() => true);

type WithContextAuthenticationOptions<T = any> = T extends object ? WithContextOptions<{ authentication?: boolean }> : boolean;
export function withAuthentication<T = any>(options?: WithContextAuthenticationOptions<T>) {
  const { context, value } = extractHttpContextAndValue(options);
  const hasAuthentication = typeof value === 'boolean' ? value : value?.authentication;
  return context.set(WITH_AUTHENTICATION, hasAuthentication);
}

export function hasAuthentication(req: HttpRequest<unknown>) {
  return req.context.get(WITH_AUTHENTICATION) !== false;
}
