import { EmptyUrlResolverParams, RouterResolver, UrlResolverOptions } from '@isaia/router';

export const resolveTutorialRoutes = (resolver: RouterResolver) => {
  return resolver.createFeatureResolver('tutorial', (rootPath) => {
    return {
      list: (options?: EmptyUrlResolverParams) => {
        const url = resolver.createUrlTree([rootPath, 'list'], options);
        return resolver.createUrlNavigableData(url);
      },
      detail: (options: UrlResolverOptions<{ tutorialId: string }>) => {
        const url = resolver.createUrlTree([rootPath, options?.params.tutorialId, 'detail'], options);
        return resolver.createUrlNavigableData(url);
      },
    };
  });
};
