@if (applicationRepository.loading()) {
  <isa-spinner label="false" position="fixed" />
}

<div class="mtm-root__force-landscape">
  <img src="assets/logo-isaia.webp" />
  <p class="typography-h1" *transloco="let translate">{{ translate('rotateToLandscapeMessage') }}</p>
</div>

<router-outlet />
