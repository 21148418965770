import { inject } from '@angular/core';
import { HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthRepository } from './auth.repository';
import { hasAuthentication } from './auth.context';
import { tap } from 'rxjs';

const HEADER_AUTHORIZATION_NAME = 'Authorization';

const TOKEN_PREFIX = 'Bearer';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const isJsonResponse = req.responseType === 'json';
  if (!hasAuthentication(req) || !isJsonResponse) {
    return next(req);
  }
  const authRepository = inject(AuthRepository);
  const header = `${TOKEN_PREFIX} ${authRepository.$token()}`;
  const headers = req.headers.set(HEADER_AUTHORIZATION_NAME, header);
  return next(req.clone({ headers })).pipe(
    tap((response) => {
      if (response instanceof HttpResponse) {
        const refreshToken = response.headers.get(HEADER_AUTHORIZATION_NAME);
        if (refreshToken) {
          const normalizedToken = refreshToken.includes(TOKEN_PREFIX) ? refreshToken.split(TOKEN_PREFIX)[1].trim() : refreshToken;
          authRepository.updateToken(normalizedToken);
        }
      }
    }),
  );
};
