import { EmptyUrlResolverParams, RouterResolver, UrlResolverOptions } from '@isaia/router';
import { resolveCompositionRoutes } from './features/composition/composition.routes-resolver';
import { resolveOrderHistoryRoutes } from './features/history/history.routes-resolver';

export const resolveOrderRoutes = (resolver: RouterResolver) => {
  return resolver.createFeatureResolver('order', (rootPath) => {
    const overview = resolver.createFeatureResolver(rootPath + '/overview', (overviewRoot) => {
      return {
        composition: (options?: Omit<UrlResolverOptions<never, { directAccessToCategorySummary?: boolean }>, 'params'>) => {
          const url = resolver.createUrlTree([overviewRoot, 'composition'], options);
          return resolver.createUrlNavigableData(url);
        },
        history: (options?: EmptyUrlResolverParams) => {
          const url = resolver.createUrlTree([overviewRoot, 'history'], options);
          return resolver.createUrlNavigableData(url);
        },
        pending: (options?: EmptyUrlResolverParams) => {
          const url = resolver.createUrlTree([overviewRoot, 'pending'], options);
          return resolver.createUrlNavigableData(url);
        },
      };
    });

    return {
      overview,
      composition: resolveCompositionRoutes(resolver, rootPath),
      history: resolveOrderHistoryRoutes(resolver, rootPath),
    };
  });
};
