import { Injectable, signal } from '@angular/core';

@Injectable()
export class PriceHidableRepository {
  private readonly state = {
    showPrice: signal<boolean>(false),
  };

  public showPrice = this.state.showPrice.asReadonly();

  public toggleShowPrice() {
    this.state.showPrice.update((showPrice) => !showPrice);
  }
}
