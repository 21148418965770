import { HttpContext, HttpHeaders, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Simplify } from 'type-fest';

export type HttpSortable<T extends string> = `+${T}` | `-${T}`;

export interface HttpPagination {
  pagination: {
    isLeaf: boolean; // check if we have more pages, on last page isLeaf = false
    currentPage: number; // current page with index 0
    totalItems: number;
  };
}

export interface HttpResponse<T> {
  application: 'Isaia Api Service';
  version: string;
  time: number;
  data: T;
}

export enum HttpErrorCode {
  GenericErrorCode = 'genericErrorCode',
  SmtpConnectionError = 'smtpConnectionError',
  SendMailError = 'sendMailError',
  AuthGenericAwsError = 'authGenericAwsError',
  AuthNotValidToken = 'authNotValidToken',
  FtpConnectionError = 'ftpConnectionError',
  OrderItemSendToProductionError = 'orderItemSendToProductionError',
  OrderItemGenericError = 'orderItemGenericError',
  CustomerExistError = 'customerExistError',
  SessionNotFound = 'sessionNotFound',
  CustomerNotFoundError = 'customerNotFoundError',
  SessionLockedStoreException = 'sessionLockedStoreException',
  SessionLockedException = 'sessionLockedException',
  OrderItemReferenceIdAlreadyExist = 'orderItemReferenceIdAlreadyExist',
  ErrorOnUploadMaxFileSize = 'errorOnUploadMaxFileSize',
}

export type HttpErrorException =
  | {
      application: string;
      version: string;
      time: 1715096207;
      error?: {
        id: string;
        status: HttpStatusCode;
        title?: string;
        code?: HttpErrorCode;
      };
    }
  | undefined;

export type HttpResponseWithPagination<T> = Simplify<HttpResponse<T> & HttpPagination>;

export interface HttpPaginationParams {
  'page[limit]'?: number; // items to show
  'page[offset]'?: number; // items offset
}

type HttpDefaultParams =
  | HttpParams
  | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };

type HttpDefaultHeaders =
  | HttpHeaders
  | {
      [header: string]: string | string[];
    };

export interface HttpGetOptions<P = HttpDefaultParams> {
  headers?: HttpDefaultHeaders;
  context?: HttpContext;
  observe?: 'body';
  params?: P;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface HttpPostOptions<P = HttpDefaultParams> {
  headers?: HttpDefaultHeaders;
  context?: HttpContext;
  observe?: 'body';
  params?: P;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface HttpDeleteOptions<B = any | null> {
  headers?: HttpDefaultHeaders;
  context?: HttpContext;
  observe?: 'body';
  params?: HttpDefaultParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: B;
}

export interface HttpPutOptions<P = HttpDefaultParams> {
  headers?: HttpDefaultHeaders;
  context?: HttpContext;
  observe?: 'body';
  params?: P;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface HttpPatchOptions<P = HttpDefaultParams> {
  headers?: HttpDefaultHeaders;
  context?: HttpContext;
  observe?: 'body';
  params?: P;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface HttpStoreOptions {
  useStore?: boolean;
  useLoading?: boolean;
}
