import { computed, Injectable, signal } from '@angular/core';
import type { RouterData } from '../router';

@Injectable({
  providedIn: 'root',
})
export class ApplicationRepository {
  private readonly state = {
    loading: signal(0),
    visibility: signal<RouterData['visibility'] | undefined>(undefined),
  };

  public loading = computed(() => this.state.loading() > 0);
  public isVisibleHeaderSession = computed(() => this.state.visibility()?.header?.session !== false);
  public isVisibleHeaderPrice = computed(() => this.state.visibility()?.header?.price !== false);

  public setVisibility(visibility?: RouterData['visibility']) {
    this.state.visibility.set(visibility);
  }

  public setLoading(isLoading: boolean) {
    this.state.loading.update((count) => {
      const nextCount = count + (isLoading ? 1 : -1);
      return nextCount <= 0 ? 0 : nextCount;
    });
  }

  // constructor() {
  //   effect(() => {
  //     console.log('loading', this.state.$loading());
  //   });
  // }
}
