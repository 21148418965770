export enum ConfigStatusType {
  Online = 'online',
  PreMaintenance = 'pre-maintenance',
  Maintenance = 'maintenance',
}

export interface Config {
  app?: {
    version: `${number}.${number}.${number}`;
  };
  status: {
    type: ConfigStatusType;
    title?: string;
    message?: string;
  };
}
