import {
  ActivatedRoute,
  NavigationBehaviorOptions,
  NavigationEnd,
  NavigationExtras,
  Params,
  Router,
  UrlCreationOptions,
} from '@angular/router';
import { inject, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { filter, map } from 'rxjs';

export interface UrlResolverOptions<P = unknown, Q extends UrlCreationOptions['queryParams'] = UrlCreationOptions['queryParams']>
  extends UrlCreationOptions {
  params: P;
  queryParams?: Q | Params;
}

export type EmptyUrlResolverParams = Omit<UrlResolverOptions<void>, 'params' | 'queryParams'>;

export const NEW_ENTITY_ID = 'new';

export class RouterResolver {
  protected readonly router = inject(Router);
  protected readonly ngZone = inject(NgZone);
  protected readonly location = inject(Location);

  public isNewEntity(id?: string | number): boolean {
    return id === NEW_ENTITY_ID;
  }

  public navigateByUrl(url: string, options?: { useZone?: boolean } & NavigationBehaviorOptions) {
    const navigate = () => this.router.navigateByUrl(url, options);
    return options?.useZone !== false ? this.ngZone.run(() => navigate()) : navigate();
  }

  public createUrlTree(commands: any[], navigationExtras?: UrlCreationOptions) {
    return this.router.createUrlTree(commands, navigationExtras).toString();
  }

  public createUrlNavigableData(url: string) {
    return { url, navigate: (options?: Parameters<typeof this.navigateByUrl>[1]) => this.navigateByUrl(url, options) };
  }

  public navigateBack() {
    return new Promise<boolean>((resolve, reject) => {
      this.ngZone.run(() => {
        this.location.back();
      });
    });
  }

  public createFeatureResolver = <T extends object, P extends string>(featurePath: P, fn?: (rootPath: `/${P}`) => T) => {
    const path = featurePath.startsWith('/') ? (featurePath as `/${P}`) : (`/${featurePath}` as const);
    return {
      ...this.createUrlNavigableData(path),
      ...(fn ? fn(path) : ({} as T)),
    };
  };

  public isActive(url: string, exact?: boolean) {
    return this.router.isActive(url, {
      paths: exact ? 'exact' : 'subset',
      queryParams: exact ? 'exact' : 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  public parseUrl(url: string) {
    return this.router.parseUrl(url);
  }

  public getCurrentState<T = any>(prop: string): T | undefined;
  public getCurrentState(): Record<string, any>;
  public getCurrentState<T = any>(prop?: string) {
    const state = this.location.getState() as Record<string, any>;
    return prop ? (state[prop] as T | undefined) : state;
  }

  public updateUrlWithoutReload(options: NavigationExtras) {
    return this.router.navigate(
      [], // Remain on current route
      { ...options, replaceUrl: true },
    );
  }

  public observeActivatedRoute(activatedRoute: ActivatedRoute) {
    return this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map(() => {
        let route = activatedRoute.firstChild;
        let child = route;

        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
            route = child;
          } else {
            child = null;
          }
        }

        return route;
      }),
    );
  }
}
