@if (title(); as _title) {
  <div class="isa-popup__title" [innerHTML]="_title"></div>
}

@if (message(); as _message) {
  <div class="isa-popup__message" [innerHTML]="_message"></div>
}

@if (hasButtons()) {
  <div class="isa-popup__button-list">
    @for (button of buttons(); track $index) {
      <isa-button
        class="{{ button.className }}"
        [color]="button.color"
        [disabled]="button.disabled"
        [type]="button.type!"
        (click)="onButtonClick($event, $index)"
      >
        {{ button.label }}
      </isa-button>
    }
  </div>
}
