import { HttpContextToken, HttpRequest } from '@angular/common/http';
import { extractHttpContextAndValue, WithContextOptions } from './http-context.model';

const HAS_CUSTOM_TIMEOUT = new HttpContextToken<WithContextOptions | number | undefined>(() => undefined);

export function withCustomTimeout(options?: WithContextOptions | number | undefined) {
  const { context, value } = extractHttpContextAndValue(options);
  return context.set(HAS_CUSTOM_TIMEOUT, value);
}

export function hasCustomTimeout(req: HttpRequest<unknown>) {
  return req.context.get(HAS_CUSTOM_TIMEOUT);
}
