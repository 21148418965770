import { computed, effect, inject, Injectable, Injector, signal, untracked } from '@angular/core';
import { catchError, defer, delay, filter, map, pairwise, repeat, retry, shareReplay, switchMap, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpResponse } from '@isaia/entity/http';
import { Config, ConfigStatusType } from './config.model';
import { withAuthentication } from '../auth/auth.context';
import { CONFIG_API_ORIGIN } from './config-api-origin.token';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { withErrorPopup } from '../http';
import { injectConfigDialog } from './config.dialog';
import { RouterResolverService } from '../router';
import { DialogService } from '@isaia/components/dialog';
import { environment } from '../../../environments/environment';
import { showApplicationUpdateMessage } from '../application/application.notify-update';

const secondsToMilliseconds = (n: number) => n * 1000;

const NO_MEMO = () => false;

const getMajorVersion = (version?: string) => version?.split('.')[0];

@Injectable()
export class ConfigService {
  private readonly API_ORIGIN = inject(CONFIG_API_ORIGIN).origin;
  private readonly POLLING = inject(CONFIG_API_ORIGIN).polling;
  private readonly http = inject(HttpClient);
  private readonly injector = inject(Injector);
  private readonly routerResolverService = inject(RouterResolverService);
  private readonly dialogService = inject(DialogService);
  private readonly configDialog = injectConfigDialog();

  /*
   * Never memoize this signal otherwise this.statusType will memoize too
   */
  private state = signal<Config | undefined>(undefined);

  /*
   * We are using "{ equal: NO_MEMO }" to prevent memoization
   * Never memoize this signal otherwise this.statusType will memoize too
   */
  public status = computed(() => this.state()?.status, { equal: NO_MEMO });

  /*
   * We are using "{ equal: NO_MEMO }" to prevent memoization
   * We want trigger ALWAYS this.ACTIONS_BY_STATUS_TYPE to prevent unexpected behaviours
   * in login process or some edge cases during status transition: "pre-maintenance -> maintenance"
   */
  public statusType = computed(() => this.status()?.type, { equal: NO_MEMO });

  public appVersion = computed(() => this.state()?.app?.version);
  public newVersionAvailable = computed(() => {
    const configAppVersion = this.appVersion();
    const isDifferentAppVersion = configAppVersion !== environment.version;
    // don't show popup when app is in maintenance or in other status
    const isOnline = this.statusType() === ConfigStatusType.Online;
    return configAppVersion && isOnline && isDifferentAppVersion;
  });

  public isForceUpdate = computed(() => {
    const configMajorVersion = getMajorVersion(this.appVersion());
    const envMajorVersion = getMajorVersion(environment.version);
    return configMajorVersion !== envMajorVersion;
  });

  public isReady = computed(() => !!this.state());
  public isReady$ = toObservable(this.isReady).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  private errorCount = signal(0);
  private readonly maxErrorCountToShowWarning = 3;
  private backOnlineAfterMaintenance = toSignal(
    toObservable(this.statusType).pipe(
      pairwise(),
      map(([prevStatus, currentStatus]) => {
        return prevStatus === ConfigStatusType.Maintenance && currentStatus === ConfigStatusType.Online;
      }),
    ),
  );

  private readonly TIMING = {
    polling: secondsToMilliseconds(this.POLLING.timingInSeconds),
    errorWaitForRetry: secondsToMilliseconds(this.POLLING.errorWaitForRetryInSeconds),
  };

  private readonly ACTIONS_BY_STATUS_TYPE: Record<ConfigStatusType, (() => void) | null> = {
    [ConfigStatusType.Maintenance]: () => {
      this.dialogService.closeAll();
      this.routerResolverService.maintenance.navigate();
    },
    [ConfigStatusType.PreMaintenance]: () => {
      this.configDialog.openPreMaintenance();
    },
    [ConfigStatusType.Online]: () => {
      this.configDialog.getActive()?.close();
      if (this.backOnlineAfterMaintenance()) {
        window.location.replace(window.location.origin);
      }
    },
  };

  constructor() {
    this.execActionOnChangeStatus();
    this.shouldShowNetworkErrorPopup();
    this.shouldShowApplicationUpdatePopup();
    this.runPollingOnConfigReady(this.TIMING.polling);
  }

  private execActionOnChangeStatus() {
    effect(
      () => {
        const statusType = this.statusType();
        untracked(() => {
          if (statusType) {
            const action = this.ACTIONS_BY_STATUS_TYPE[statusType];
            action?.();
          }
        });
      },
      { injector: this.injector },
    );
  }

  private shouldShowNetworkErrorPopup() {
    effect(
      () => {
        if (this.errorCount() >= this.maxErrorCountToShowWarning) {
          this.configDialog.openOffline();
        }
      },
      { injector: this.injector },
    );
  }

  public getConfig() {
    return this.http
      .get<HttpResponse<Config>>(this.API_ORIGIN, {
        context: withAuthentication({ authentication: false, context: withErrorPopup(false) }),
      })
      .pipe(
        // timeout(1250),
        tap((res) => {
          this.state.set(res.data);
          this.errorCount.set(0);
        }),
        catchError((e) => {
          this.errorCount.update((n) => n + 1);
          return throwError(e);
        }),
        retry({ delay: this.TIMING.errorWaitForRetry }),
      );
  }

  private runPollingOnConfigReady(timing: number) {
    this.isReady$
      .pipe(
        filter((ready) => ready),
        delay(timing),
        switchMap(() => {
          return defer(() => this.getConfig()).pipe(repeat({ delay: timing }));
        }),
      )
      .subscribe();
  }

  private shouldShowApplicationUpdatePopup() {
    effect(
      () => {
        if (this.newVersionAvailable()) {
          showApplicationUpdateMessage(this.isForceUpdate());
        }
      },
      { injector: this.injector },
    );
  }
}
