import { Route } from '@angular/router';
import { canActivateAuth, canActivateShell } from './core/auth';
import { canActivateMaintenance, canActivateApplicationByConfigStatus, hasConfigReady } from './core/config';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./features/maintenance/maintenance.routes'),
    canActivate: [hasConfigReady, canActivateMaintenance],
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.routes'),
    canActivate: [hasConfigReady, canActivateApplicationByConfigStatus, canActivateAuth],
  },
  {
    path: '',
    loadChildren: () => import('./features/shell/shell.routes'),
    canActivate: [hasConfigReady, canActivateApplicationByConfigStatus, canActivateShell],
  },
];
