import { HttpContext } from '@angular/common/http';
import { Simplify } from 'type-fest';

const isObject = <T = any>(value: T) => value && typeof value === 'object' && !Array.isArray(value);
type ExtractHttpContextValue<T = any> = T extends { context?: HttpContext } ? Simplify<Omit<T, 'context'>> : T;
export type WithContextOptions<T extends object = object> = Simplify<{ context?: HttpContext } & T>;

export function extractHttpContextAndValue<T = any>(options?: T): { context: HttpContext; value: ExtractHttpContextValue<T> } {
  if (isObject(options) && (options as any).context instanceof HttpContext) {
    const { context, ...rest } = options as any;
    return { context: context as HttpContext, value: rest as ExtractHttpContextValue<T> };
  }
  return { context: new HttpContext(), value: options as unknown as ExtractHttpContextValue<T> };
}
