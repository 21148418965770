import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';

export const removeEmptyQueryParamsInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  let params = request.params;
  for (const key of request.params.keys()) {
    if ([undefined, null, 'undefined', 'null'].includes(params.get(key))) {
      params = params.delete(key, undefined);
    }
  }
  const clone = request.clone({ params });
  return next(clone);
};
