import { translate } from '@jsverse/transloco';
import { createPopupButton, DialogService } from '@isaia/components/dialog';
import { firstValueFrom, switchMap, tap } from 'rxjs';
import { inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@isaia/i18n/language';

export function injectConfigDialog() {
  const dialogService = inject(DialogService);
  const languageService = inject(LanguageService);
  let active: MatDialogRef<unknown, unknown> | null | undefined;
  const popupId = {
    offline: 'isa-popup--config-offline',
    preMaintenance: 'isa-popup--config-pre-maintenance',
  };

  const open = (id: string, factory: () => MatDialogRef<unknown, unknown>) => {
    if (active?.id === id) {
      return Promise.resolve();
    }

    active?.close();
    active = null;
    const afterClosed$ = languageService.load(languageService.getActiveLang()).pipe(
      switchMap(() => {
        const ref = factory();
        active = ref;
        return ref.afterClosed().pipe(
          tap(() => {
            active = null;
          }),
        );
      }),
    );
    return firstValueFrom(afterClosed$);
  };

  return {
    getActive() {
      return active;
    },

    openPreMaintenance() {
      const id = popupId.preMaintenance;
      return open(id, () => {
        return dialogService.openPopup(
          {
            title: translate('popupConfigPreMaintenanceTitle'),
            message: translate('popupConfigPreMaintenanceMessage'),
            buttons: [createPopupButton({ label: translate('popupConfigPreMaintenanceButtonOk') })],
          },
          { id },
        );
      });
    },

    openOffline() {
      const id = popupId.offline;
      return open(id, () => {
        return dialogService.openPopup(
          {
            title: translate('popupConfigOfflineTitle'),
            message: translate('popupConfigOfflineMessage'),
            buttons: [createPopupButton({ label: translate('popupConfigOfflineButtonOk') })],
          },
          { id },
        );
      });
    },
  };
}
