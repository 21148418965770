import { RouterResolver, UrlResolverOptions } from '@isaia/router';
import { Simplify } from 'type-fest';

export type CompositionSummaryQueryParams = Simplify<{
  'fabric[category]'?: string | number;
  'fabric[code]'?: string;
  'fabric[color]'?: string;
  deliveryDate?: string;
  quantity?: string;
  price?: string;
  frecciarossa?: boolean;
  bespoke?: boolean;
  isCustomPrice?: boolean;
  externalReferenceId?: string;
  suitVest?: boolean;
  [key: `taxonomies[${string}]`]: string;
  basketProductId?: string;
  orderItemId?: string;
}>;

export interface CompositionCustomerListQueryParams extends CompositionSummaryQueryParams {
  categoryId?: string;
}

export const resolveCompositionRoutes = (resolver: RouterResolver, rootParent = '') => {
  return resolver.createFeatureResolver(rootParent + '/composition', (rootPath) => {
    const category = resolver.createFeatureResolver(rootPath + '/category', (categoryRootPath) => {
      return {
        summary: (options?: UrlResolverOptions<{ categoryId: string }, CompositionSummaryQueryParams>) => {
          const url = resolver.createUrlTree([categoryRootPath, options?.params.categoryId, 'summary'], options);
          return resolver.createUrlNavigableData(url);
        },
      };
    });

    const customer = resolver.createFeatureResolver(rootPath + '/customer', (customerRootPath) => {
      return {
        list: (options?: Omit<UrlResolverOptions<void, CompositionCustomerListQueryParams>, 'params'>) => {
          const url = resolver.createUrlTree([customerRootPath, 'list'], options);
          return resolver.createUrlNavigableData(url);
        },
      };
    });

    return {
      category,
      customer,
    };
  });
};
