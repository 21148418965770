import { ActivatedRoute, ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy } from '@angular/router';
import { ComponentRef, inject, Provider } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

export class MadeToMeasureRouterReuseStrategy implements RouteReuseStrategy {
  private readonly location = inject(Location);
  private readonly locationChange = this.detectLocationBack();
  private handlers: Map<Route, DetachedRouteHandle & { componentRef?: ComponentRef<any>; route?: { value: ActivatedRoute } }> = new Map();

  private detectLocationBack() {
    let timeoutId: number;
    const isBack$ = new BehaviorSubject(false);
    this.location.subscribe((e) => isBack$.next(!!e.pop));
    return {
      isBack: () => isBack$.getValue(),
      detectLocationEnd: () => {
        timeoutId && clearTimeout(timeoutId);
        timeoutId = setTimeout(() => isBack$.next(false)) as unknown as number;
      },
    };
  }

  // Should we store the route? Defaults to false.
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data['storeRoute'];
  }

  // Store the route
  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // Ex. users/1, users/2, users/3, ...
    // const key = getFullPath(route);
    // this.storedRoutes[key] = { route, handle };
    if (!route.routeConfig) {
      return;
    }
    this.handlers.set(route.routeConfig, handle);
  }

  // Should we retrieve a route from the store?
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // The RouteReuseStrategy needs to be updated to not retrieve the same handle for both a parent and a child.
    // The update above prevents the parent loadChildren config from being stored/retrieved at all.
    if (!route.component || !route.routeConfig) {
      return false;
    }

    this.locationChange.detectLocationEnd();

    const stored = this.handlers.get(route.routeConfig);
    if (stored) {
      // Compare params and queryParams.
      // Params, however, have already been compared because the key includes them.
      // const snapshot = (stored.route?.value as any)['_futureSnapshot'] as ActivatedRouteSnapshot | undefined;
      // const paramsMatch = isEqual(route.params, snapshot?.params);
      // const queryParamsMatch = isEqual(route.queryParams, snapshot?.queryParams);
      const canReuse = this.locationChange.isBack();
      if (!canReuse) {
        stored.componentRef?.destroy();
        this.handlers.delete(route.routeConfig);
      }
      return canReuse;
    }
    return false;
  }

  // Retrieve from the store (just the Handle)
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig || !this.handlers.has(route.routeConfig)) {
      return null;
    }
    return this.handlers.get(route.routeConfig)!;
  }

  // Should the route be reused?
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  public resetCacheOf(tagName: string) {
    const cache = Array.from(this.handlers.entries());
    for (const [key, value] of cache) {
      const ref = value.componentRef;
      const el = ref?.location.nativeElement as HTMLElement | undefined;
      if (el?.tagName?.toLowerCase() === tagName) {
        ref?.destroy();
        this.handlers.delete(key);
        break;
      }
    }
  }
}

export function provideRouterReuseStrategy(): Provider {
  return { provide: RouteReuseStrategy, useClass: MadeToMeasureRouterReuseStrategy };
}
