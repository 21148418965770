import { EmptyUrlResolverParams, RouterResolver, UrlResolverOptions } from '@isaia/router';

export const resolveOrderHistoryRoutes = (resolver: RouterResolver, rootParent = '') => {
  return resolver.createFeatureResolver(rootParent + '/history', (historyPath) => {
    return {
      detail: (options: UrlResolverOptions<{ orderId: string }>) => {
        const url = resolver.createUrlTree([historyPath, options.params.orderId, 'detail'], options);
        return resolver.createUrlNavigableData(url);
      },
      dwh: resolver.createFeatureResolver(historyPath + '/dwh', (dwhPath) => {
        return {
          list: (options?: EmptyUrlResolverParams) => {
            const url = resolver.createUrlTree([dwhPath, 'list'], options);
            return resolver.createUrlNavigableData(url);
          },
        };
      }),
    };
  });
};
