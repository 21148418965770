import { filter, map, pairwise, shareReplay, startWith } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { inject, InjectionToken } from '@angular/core';

export const NAVIGATION_END = new InjectionToken('[Isaia] navigation end', {
  providedIn: 'root',
  factory: () => {
    return inject(Router).events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map((e) => e.urlAfterRedirects),
      startWith('app-bootstrap'),
      pairwise(),
      map(([prev, next]) => ({ prev, next })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  },
});
